/* eslint-plugin-disable unicorn/filename-case */
import HeaderImage from '../blocks/HeaderImage';
import React from 'react';
import { graphql } from 'gatsby';
// import cleanCockpitData from 'Utils/cleanCockpitData';

import Metadata from '../components/Metadata';
import Posting from '../components/JobPosting';
import DefaultLayout from '../layout/Default';
import * as styles from './job.module.css';

const JobsPage = ({ data, location }) => {
  const { siteData, pageData, jobs } = data;
  const { title: siteTitle, canonicalSiteUrl } = siteData.siteMetadata;
  const { content, header, metadata, opengraph } = pageData;
  const { title, text, pageID, footer } = content;

  return (
    <DefaultLayout>
      <HeaderImage data={header} minHeight={150} />
      <div className="defaultWidth" id={pageID}>
        <div>
          <h2>{title}</h2>
          <div className="textPage" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        {jobs && jobs.edges && jobs.edges.length > 0 && (
          <React.Fragment>
            <div>
              {jobs.edges.map(({ node: job }) => (
                <Posting data={job} />
              ))}
            </div>
            <div className="textPage" dangerouslySetInnerHTML={{ __html: footer }} />
          </React.Fragment>
        )}
        {!(jobs && jobs.edges && jobs.edges.length > 0) && (
          <div style={{ textAlign: 'center', margin: '3em 0' }}>Es sind derzeit keine offenen Stellen verfügbar.</div>
        )}
      </div>
      <Metadata
        data={metadata}
        opengraph={opengraph}
        defaultTitle={`${title} : ${siteTitle}`}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default /* cleanCockpitData( */ JobsPage /*) */;

export const JobsPageQuery = graphql`
  query JobsPageQuery($language: String) {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    pageData: cockpitPageJobs(lang: { eq: $language }) {
      content {
        title
        pageID
        text
        footer
      }

      header {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        madeInAustriaOverlay
        image {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1600, placeholder: NONE)
          }
        }
        mobileImage {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 900, placeholder: NONE)
          }
        }
      }

      metadata {
        title
        metakeywords
        metadescription
      }

      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
              width
              height
              aspectRatio
              originalName
            }
          }
        }
      }
    }

    jobs: allCockpitJobs(filter: { published: { eq: true }, lang: { eq: $language } }) {
      edges {
        node {
          id
          title
          published
          intro
          footer
          requirements
          tasks
        }
      }
    }
  }
`;
