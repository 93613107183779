import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './JobPosting.module.css';
import { useTranslation } from 'react-i18next';

JobPosting.propTypes = {
  data: PropTypes.shape().isRequired,
};
JobPosting.defaultProps = {};

function JobPosting({ data }) {
  const { t } = useTranslation('jobs');
  const { title, intro, footer, tasks, requirements } = data;
  return (
    <section className={styles.Posting}>
      <h3>{title}</h3>
      <div dangerouslySetInnerHTML={{ __html: intro }} />
      <h4>{t('skills')}</h4>
      <ul>
        {requirements.map((requirement) => (
          <li key={requirement}>{requirement}</li>
        ))}
      </ul>
      <h4>{t('tasks')}</h4>
      <ul>
        {tasks.map((task) => (
          <li key={task}>{task}</li>
        ))}
      </ul>
      <div dangerouslySetInnerHTML={{ __html: footer }} />
    </section>
  );
}

export default JobPosting;
